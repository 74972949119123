/**
 * Primary font - Quicksand
 */
@font-face {
  font-family: 'Quicksand-Light';
  font-weight: 300;
  src: local('Quicksand-Light'),
    url(./assets/fonts/Quicksand-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Regular';
  font-weight: 400;
  src: local('Quicksand-Regular'),
    url(./assets/fonts/Quicksand-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Medium';
  font-weight: 500;
  src: local('Quicksand-Medium'),
    url(./assets/fonts/Quicksand-Medium.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-SemiBold';
  font-weight: 600;
  src: local('Quicksand-SemiBold'),
    url(./assets/fonts/Quicksand-SemiBold.ttf) format('truetype');
}

@font-face {
  font-family: 'Quicksand-Bold';
  font-weight: 700;
  src: local('Quicksand-Bold'),
    url(./assets/fonts/Quicksand-Bold.ttf) format('truetype');
}

/**
 * Secondary font - Lato
 */
@font-face {
  font-family: 'Lato-Light';
  font-weight: 300;
  src: local('Lato-Light'),
    url(./assets/fonts/Lato-Light.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Regular';
  font-weight: 400;
  src: local('Lato-Regular'),
    url(./assets/fonts/Lato-Regular.ttf) format('truetype');
}

@font-face {
  font-family: 'Lato-Bold';
  font-weight: 700;
  src: local('Lato-Bold'), url(./assets/fonts/Lato-Bold.ttf) format('truetype');
}
